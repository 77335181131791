import {Component} from 'react'
import {NotificationManager} from 'react-notifications'

class MessageService extends Component {
    static showSuccessNotification(message) {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.success(message + '.', 'Customs', 1500);
    }

    static showInfoNotification(message) {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.success(message + '.', 'Customs', 3000);
    }    

    static showWarningNotification(message) {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.warning(message + '.', 'Customs', 1500);
    }
}

export default MessageService;
