// assets
import { 
    IconFileDollar,
    IconCoin,
    IconFileInvoice
} from '@tabler/icons';

import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const accts_receivable = {
    id: 'accts_receivable',
    title: 'Accts Receivable',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('ar_charges|ar_invoices|ar_auto_charges')) {
    accts_receivable.children.push({
        id: 'charges',
        title: 'Charges',
        type: 'item',
        url: '/charges',
        icon: IconFileDollar
    });

    accts_receivable.children.push({
        id: 'automatic_charges',
        title: 'Automatic Charges',
        type: 'item',
        url: '/automatic_charges',
        icon: IconCoin
    });

    accts_receivable.children.push({
        id: 'invoices',
        title: 'Invoices',
        type: 'item',
        url: '/invoices',
        icon: IconFileInvoice
    });
}

export default accts_receivable;
