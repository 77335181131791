// assets
import { 
    IconBuildingSkyscraper, 
    IconRoad, 
    IconTruckDelivery, 
    IconBrandSocketIo, 
    IconBrandAbstract,
    IconBrandDingtalk,
    IconRepeat,
    IconClipboard,
    IconTableExport,
    IconAnchor,
    IconForklift,
    IconTrain,
    IconCertificate2,
    IconCirclesRelation,
    IconBriefcase
} from '@tabler/icons';

import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const trade = {
    id: 'trade',
    title: 'Trade',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('traffics')) {
    trade.children.push({
        id: 'traffic',
        title: 'Traffics',
        type: 'item',
        url: '/traffics',
        icon: IconRoad,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('delivery_orders')) {
    trade.children.push({
        id: 'delivery_orders',
        title: 'Delivery orders',
        type: 'item',
        url: '/delivery_orders',
        icon: IconTruckDelivery,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('cbp_transmition_trade')) {
    trade.children.push({
        id: 'cbp_transmition_trade',
        title: 'CBP Transmission Trade',
        type: 'item',
        url: '/cbp_transmition_trade',
        icon: IconBrandSocketIo,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('entries')) {
      trade.children.push({
        id: 'entries',
        title: 'Entries',
        type: 'item',
        url: '/entries',
        icon: IconBrandAbstract,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('cbp_statements')) {
    trade.children.push({
        id: 'cbp_statements',
        title: 'CBP Statements',
        type: 'item',
        url: '/cbp_statements',
        icon: IconBrandDingtalk,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('inbonds')) {
    trade.children.push({
        id: 'inbonds',
        title: 'Inbonds',
        type: 'item',
        url: '/inbonds',
        icon: IconRepeat,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('general_orders')) {
    trade.children.push({
        id: 'general_orders',
        title: 'General Orders',
        type: 'item',
        url: '/general_orders',
        icon: IconClipboard,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('blue_letter')) {
    trade.children.push({
        id: 'blue_letter',
        title: 'Blue Letter',
        type: 'item',
        url: '/blue_letter',
        icon: IconTableExport,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('isf_entry')) {
    trade.children.push({
        id: 'isf',
        title: 'ISF',
        type: 'item',
        url: '/isf',
        icon: IconAnchor,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('inbond_wp_entry')) {
    trade.children.push({
        id: 'arrive_exports',
        title: 'Arrive/Exports',
        type: 'item',
        url: '/arrive_exports',
        icon: IconForklift,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('shippers')) {
    trade.children.push({
        id: 'shippers',
        title: 'Shippers',
        type: 'item',
        url: '/shippers',
        icon: IconTrain,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('emanif')) {
    trade.children.push({
        id: 'emanifests',
        title: 'Truck E-Manifest',
        type: 'item',
        url: '/emanifests',
        icon: IconTruckDelivery,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('ddtc_licenses')) {
    trade.children.push({
        id: 'ddtc_licenses',
        title: 'DDTC Licenses',
        type: 'item',
        url: '/ddtc_licenses',
        icon: IconCertificate2,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('recon_entry')) {
    trade.children.push({
        id: 'recon_entry',
        title: 'Recon Entries',
        type: 'item',
        url: '/recon_entries',
        icon: IconCirclesRelation,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('pga_entity_records')) {
    trade.children.push({
        id: 'pga_entities',
        title: 'PGA Entities',
        type: 'item',
        url: '/pga_entities',
        icon: IconBuildingSkyscraper,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('carriers')) {
    trade.children.push({
        id: 'carriers',
        title: 'Carriers',
        type: 'item',
        url: '/carriers',
        icon: IconBriefcase,
        breadcrumbs: false
    });
}

export default trade;
