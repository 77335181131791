// assets
import { IconClipboardText } from '@tabler/icons';

// constant
const icons = {
    IconClipboardText
};

import SecurityService from "services/SecurityService";

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utils',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('orders')) {
    utilities.children.push({
        id: 'orders',
        title: 'Orders',
        type: 'item',
        url: '/orders',
        icon: icons.IconClipboardText,
        breadcrumbs: false
    });    
}

export default utilities;
