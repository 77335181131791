let es = {
  translation: {
    'Home': 'Inicio',
    'Welcome': 'Bienvenido',
    'Logout': 'Cerrar sesión',
    'English': 'Inglés',
    'Spanish': 'Español',
    'Portuguese': 'Portugués',
    'Event logs': 'Bitácora de acciones',
    'Event Logs': 'Bitácora de acciones',
    'Transmission logs': 'Bitácora de transmisiones',
    'Transmission Logs': 'Bitácora de transmisiones',
    'Roles': 'Roles',
    'Assisted auth': 'Autenticación asistida',
    'Permissions': 'Permisos',
    'Record details': 'Detalles del registro',

    'Create': 'Adicionar',
    'Update': 'Actualizar',
    'Edit': 'Modificar',
    'Delete': 'Eliminar',
    'Cancel': 'Cancelar',
    'Name': 'Nombre',
    'Name Spanish': 'Nombre Español',
    'Name English': 'Nombre Inglés',
    'Name Portuguese': 'Nombre Portugués',
    'Create record': 'Adicionar registro',
    'Update record': 'Modificar registro',
    'Are you sure to delete this record?': '¿Está seguro de eliminar este registro?',
    'This operation is restricted to your user role': 'Esta operación no está permitida para su rol de usuario',
    'Permissions are already set for this user role': 'Los permisos de este rol de usuario ya han sido configurados',
    'Permissions of Role': 'Permisos del rol',
    'Read': 'Acceder',
    'Write': 'Modificar',
    'Remove': 'Eliminar',
    'List': 'Listar',
    'Has already been taken': 'Ya ha sido utilizado',
    'Range overflow': 'Por encima de lo permitido',
    'Pattern mismatch': 'Patrón incorrecto',
    'Range underflow': 'Por debajo de lo permitido',
    'Too long': 'Demasiado largo',
    'Type mismatch': 'Tipo de dato no válido',
    'Required': 'Requerido',
    'The operation was successful': 'La operación fue satisfactoria',
    'The operation could not be completed. Please, try again': 'No se pudo completar la operación. Por favor, inténtelo nuevamente',
    'The operation could not be completed. Possibly the instance has related records': 'La operacion no pudo ser completada. Posiblemente la instancia tiene registros relacionados',

    'Users': 'Usuarios',
    'Last visit': 'Última visita',
    'More than 100 days': 'Hace más de 100 dias',
    'days': 'dias',
    'Never': 'Nunca',

    'Change password': 'Cambiar contraseña',
    'Update profile': 'Actualizar perfil',
    'Photo': 'Foto',
    'Assisted authentication': 'Autenticación asistida',
    'New password': 'Contraseña nueva',
    'Confirm password': 'Confirmar contraseña',
    'Password and confirm password do not match': 'La nueva contraseña y la contraseña de confirmación no coindicen',
    'Current password is not valid': 'La contraseña actual no es correcta',
    'New password is not secure enough': 'La nueva contraseña no es segura',
    'New password can not be equal to current password': 'La nueva contraseña no puede ser igual a la contraseña actual',
    'Edit profile': 'Editar perfil',
    'Last name': 'Primer apellido',
    'Second last name': 'Segundo apellido',
    'Password should have at least 8 characters with a combination of letters, numbers and special characters. Sequences such as 123 or abc are considered insecure.': 'La contraseña debe tener al menos 8 caracteres con una combinación de letras, números y caracteres especiales. Secuencias como 123 o abc se consideran inseguras.',

    'Clic on image to change profile photo': 'Clic en la imagen para cambiar la foto de perfil',

    'User profile': 'Perfil de usuario',
    'Username': 'Nombre de usuario',
    'Exit': 'Salir',
    'Back': 'Atrás',

    'Profile': 'Perfil de usuario',
    'Orders': 'Órdenes',
    'Subtype': 'Subtipo',
    'Orders count': 'Cantidad de órdenes',
    'Order': 'Orden',
    'Records count': 'Cantidad de registros',

    'Import': 'Importar',
    'Export CSV': 'Exportar CSV',

    'Utils': 'Útiles',
    'Others': 'Otros',
    'Template': 'Modelo',
    'Confirm': 'Confirmar',
    'Confirm order': 'Confirmar orden',

    'Part number': 'Número',
    'Description': 'Descripción',
    'Quantity': 'Cantidad',
    'Metric': 'Unidad de medida',
    'Price': 'Precio',
    'Total': 'Total',
    'Date': 'Fecha',

    'Unconfirmed': 'Sin confirmar',
    'Confirmed': 'Confirmada',
    'State': 'Estado',

    'Code': 'Código',
    'Active': 'Activo',
    'Settings': 'Configuración',
    'Consignee types': 'Tipos de consignatarios',
    'Companies': 'Compañías',
    'Traffic': 'Tráfico',
    'Bond types': 'Tipos de bonos',
    'Reconciliation issues': 'Asuntos de conciliación',
    'Pmt types': 'Tipos de pago',
    'Manif dest from': 'Destinos múltiples desde',
    'Consecutives': 'Consecutivos',
    'Invoice formats': 'Formatos de factura',
    'Reach limit actions': 'Acciones de límite de alcance',

    'Empty validation actions': 'Acciones ante valores nulos',
    'Weight in lines': 'Pesos en línea',
    'Electronic filing options': 'Opciones de presentación electrónica',
    'Rel date mismatch actions': 'Acciones de desajuste de fecha real',
    'Document templates': 'Plantillas de documentos',
    'Traffic types': 'Tipos de tráfico',
    'Traffic statuses': 'Estados del tráfico',
    'Ports': 'Puertos',
    'Carriers': 'Transportistas',
    'Unit metrics': 'Unidades de medida',
    'Manufacturing ids': 'Identificadores de fabricación',
    'Mode of transports': 'Modos de transporte',
    'Loc of goods': 'Ubicación de bienes',
    'Del. terms': 'Terminos Del.',

    'Management': 'Administración',
    'System status': 'Estado del sistema',
    'Users count': 'Cantidad de usuarios'
  }
}

export default es;
