// assets
import { 
    IconFileBroken
} from '@tabler/icons';

import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const drawbacks = {
    id: 'drawbacks',
    title: 'Drawbacks',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('entry_drawbacks')) {
    drawbacks.children.push({
        id: 'drawback_7551',
        title: 'Entry 7551',
        type: 'item',
        url: '/drawback_7551',
        icon: IconFileBroken,
        breadcrumbs: false
    });
    
    drawbacks.children.push({
        id: 'drawback_7553',
        title: 'Entry 7553',
        type: 'item',
        url: '/drawback_7553',
        icon: IconFileBroken,
        breadcrumbs: false
    });
}

export default drawbacks;
