// assets
import { 
    IconShip,
    IconArrowAutofitContent,
    IconAd2,
    IconCalculator,
    IconSettings2
} from '@tabler/icons';

import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const reports = {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('ships_monitoring')) {
    reports.children.push({
        id: 'ships_monitoring',
        title: 'Ships Monitoring',
        type: 'item',
        url: '/ships_monitoring',
        icon: IconShip,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('cargo_status')) {
    reports.children.push({
        id: 'cargo_status',
        title: 'Cargo Status',
        type: 'item',
        url: '/cargo_status',
        icon: IconArrowAutofitContent,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('inventory_records')) {
    reports.children.push({
        id: 'inventory',
        title: 'Inventory',
        type: 'item',
        url: '/inventory',
        icon: IconAd2,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('total_entries')) {
    reports.children.push({
        id: 'total_entries_group',
        title: 'Total Entries',
        type: 'collapse',
        icon: IconCalculator,
        breadcrumbs: false,
        children: [
            {
                id: 'total_entries',
                title: 'By Date Range',
                type: 'item',
                url: '/total_entries'
            },
            {
                id: 'total_entries_month',
                title: 'Current Month',
                type: 'item',
                url: '/total_entries_month'
            },
            {
                id: 'total_entries_year',
                title: 'Current Year',
                type: 'item',
                url: '/total_entries_year'
            }
        ]
    });
}

if (SecurityService.userIsGrantedTo('flexible_reports')) {
    reports.children.push({
        id: 'flexible_reports',
        title: 'Flexible reports',
        type: 'item',
        url: '/flexible_reports',
        icon: IconSettings2,
        breadcrumbs: false
    });
}

export default reports;
