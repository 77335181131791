// assets
import { 
    IconBuildingSkyscraper, 
    IconBrandSuperhuman,
    IconHelp
} from '@tabler/icons';

import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const companies = {
    id: 'general',
    title: 'General',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('companies')) {
    companies.children.push({
        id: 'companies',
        title: 'Companies',
        type: 'item',
        url: '/companies',
        icon: IconBuildingSkyscraper,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('product_file')) {
    companies.children.push({
        id: 'product_files',
        title: 'Product Files',
        type: 'item',
        url: '/product_files',
        icon: IconBrandSuperhuman,
        breadcrumbs: false
    });
}

if (SecurityService.userIsGrantedTo('system_support')) {
    companies.children.push({
        id: 'system_support',
        title: 'System Support',
        type: 'item',
        url: '/system_support',
        icon: IconHelp,
        breadcrumbs: false
    });
}

export default companies;
